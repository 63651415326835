import {
  authorize,
  logout,
  getKolturaSession,
  getSession,
  extendSession,
  updateSessionStorage,
} from "./js/services";
import { toggleLogin } from "./js/view";
import Starfleet from "./js/Starfleet";

const starfleet = new Starfleet({
  SESSION_ITEM_NAME,
  CLIENT_ID,
  SUPPORTED_IDPS,
  PRIVACY_ITEM_NAME,
  LOGIN_HINTS_ITEM_NAME,
  SSO_TIMEOUT,
});
const locale = document.getElementsByTagName("html")[0].getAttribute("lang");

let nvodSession;

const docReadyPromise = new Promise((resolve) => {
  document.addEventListener("DOMContentLoaded", () => {
    resolve();
  });
});

const sessionPromise = new Promise((resolve) => {
  (async () => {
    nvodSession = await starfleet.getSession(locale);
    resolve();
  })();
});

Promise.all([sessionPromise, docReadyPromise]).then(() => {
  init();
});

const loginElement = document.getElementById("nod-login");
const logoutElement = document.getElementById("nod-logout");
const profileElement = document.getElementById("myprofile");

const init = () => {
  // show/hide login, logout, and profile links depending on the login state
  toggleLogin(loginElement, logoutElement, profileElement, nvodSession);

  // only runs if the user is logged in
  if (nvodSession) {
    getKolturaSession(nvodSession);
  }
};

loginElement.onclick = (e) => {
  e.preventDefault();
  authorize();
};

logoutElement.onclick = (e) => {
  e.preventDefault();
  logout();
  toggleLogin(loginElement, logoutElement, profileElement);
};

window.NVIDIAGDC = window.NVIDIAGDC || {};
window.NVIDIAGDC.NOD = window.NVIDIAGDC.NOD || {};
window.NVIDIAGDC.NOD.login = () => {
  authorize();
};
window.NVIDIAGDC.NOD.getSession = () => {
  return getSession();
};

window.NVIDIAGDC.NOD.logout = () => {
  console.log("logout API called");
  logout();
  toggleLogin(loginElement, logoutElement, profileElement);
};

window.NVIDIAGDC.NOD.extendSession = async () => {
  const session = JSON.parse(localStorage.getItem(SESSION_ITEM_NAME));
  try {
    if (session && session.refresh_token) {
      const extendedSession = await extendSession(session.refresh_token);
      updateSessionStorage(extendedSession.data);
      NVIDIAGDC.Browser.reMountAll();
      toggleLogin(loginElement, logoutElement, profileElement, extendedSession);
    }
  } catch (err) {
    console.log("error trying to extend the session", err);
    logout();
    toggleLogin(loginElement, logoutElement, profileElement);
  }
};
