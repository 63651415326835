import "core-js/features/url-search-params";
import axios from "axios";

axios.defaults.withCredentials = true;

const locale = document.getElementsByTagName("html")[0].getAttribute("lang");
const LOCALIZED_VOLTRON_BASE = `${
  locale === "zh-cn" ? VOLTRON_BASE_CN : VOLTRON_BASE
}`;

// function that reads the IDP id from local storage
// it reads first the nvod item. If it does not exist, it looks for the item name of the last login
// it verifies that the idp id is supported before returning it
// this is done to support true SSO accross dmo clients

export const getSession = () => {
  const session = JSON.parse(localStorage.getItem(SESSION_ITEM_NAME));
  if (session && new Date(session.expiration) > new Date()) {
    // session object exists and has not expired
    Cookies.set(DEVZONE_LOGGED_IN_COOKIE_NAME, session?.email, {
      expires: new Date(session.expiration),
      secure: true,
      path: "/",
      domain: ".nvidia.com",
    });
    return { ...session, message: "VALID_SESSION" };
  } else if (session) {
    // session object exists and has expired
    return { message: "EXPIRED_SESSION" };
  }
  // session object does not exist
  return { message: "NO_SESSION" };
};

export const authorize = (login_hint) => {
  const [lang, region] = locale.split("-");
  const searchParams = new URLSearchParams({
    redirect_uri: window.location.href,
    client_id: CLIENT_ID,
    ui_locales: `${lang}-${region.toUpperCase()}`,
  });
  let loginPath = locale === "zh-cn" ? AUTH_URL_CN : AUTH_URL;
  if (login_hint) {
    searchParams.append("login_hint", login_hint);
    loginPath = DMO_AUTH_URL;
  }
  window.location.href = `${loginPath}?${searchParams.toString()}`;
};

export const logout = () => {
  localStorage.removeItem(SESSION_ITEM_NAME);
  localStorage.removeItem(KOLTURA_SESSION_LOADED_ITEM_NAME);
  localStorage.removeItem(PRIVACY_ITEM_NAME);
  localStorage.removeItem(LOGIN_HINTS_ITEM_NAME);
  Cookies.remove(DEVZONE_LOGGED_IN_COOKIE_NAME, {
    path: "/",
    domain: ".nvidia.com",
  });
  axios.get(`${LOCALIZED_VOLTRON_BASE}${KOLTURA_LOGOUT_URL}`).then(() => {
    NVIDIAGDC.Browser.reMountAll();
  });
};

export const getKolturaSession = (nvodSession) => {
  const kolturaSessionLoaded = localStorage.getItem(
    KOLTURA_SESSION_LOADED_ITEM_NAME
  );
  // only make a call to get koltura session when there is a starfleet session
  // and the call was not made before
  if (nvodSession && !kolturaSessionLoaded) {
    let idToken = nvodSession.id_token;
    console.log(
      "login endpoint",
      `${LOCALIZED_VOLTRON_BASE}${KOLTURA_LOGIN_URL}`
    );
    axios
      .get(`${LOCALIZED_VOLTRON_BASE}${KOLTURA_LOGIN_URL}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        localStorage.setItem(KOLTURA_SESSION_LOADED_ITEM_NAME, true);
        NVIDIAGDC.Browser.reMountAll();
      });
  }
};

export const extendSession = (refresh_token) => {
  return axios.post(
    TOKEN_URL,
    new URLSearchParams({ grant_type: GRANT_TYPE, refresh_token })
  );
};

export const updateSessionStorage = (newSession) => {
  let expirationDate = new Date();
  expirationDate = new Date(
    expirationDate.getTime() + newSession.expires_in * 1000
  );
  const session = JSON.parse(localStorage.getItem(SESSION_ITEM_NAME));
  session.expiration = expirationDate;
  session.id_token = newSession.id_token;
  session.refresh_token = newSession.refresh_token;
  session.access_token = newSession.access_token;
  localStorage.setItem(SESSION_ITEM_NAME, JSON.stringify(session));
};
